var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9"},[_c('div',{staticClass:"mn-vid-sc single_video"},[_c('div',{staticClass:"vid-1"},[_c('div',{staticClass:"vid-info"},[_c('h3',[_vm._v(" "+_vm._s(_vm.video.description)+" "),_c('div',{staticClass:"float-right"},[(_vm.video.isActive === 1)?_c('span',{staticClass:"badge badge-success"},[_vm._v(_vm._s(_vm.$t("online")))]):_c('span',{staticClass:"badge badge-danger"},[_vm._v(_vm._s(_vm.$t("offline")))]),(_vm.video.isPreview === 1)?_c('span',{staticClass:"badge mx-2 badge-success"},[_vm._v(_vm._s(_vm.$t("previewVideo")))]):_vm._e(),(_vm.video.interval > 0)?_c('span',{staticClass:"badge mx-2 badge-success"},[_vm._v(_vm._s(_vm.$t("waitDayOfVideo", { day: _vm.video.interval })))]):_vm._e()])])])]),_c('div',{staticClass:"abt-mk"},[_c('div',{staticClass:"info-pr-sec"},[_c('ul',{staticClass:"chan_cantrz"},[_c('li',[_c('button',{staticClass:"btn btn-pill subscribe",attrs:{"href":"javascript:void(0)","title":""},on:{"click":function($event){return _vm.$router.replace({
                    query: {
                      ..._vm.$route.query,
                      modal: 'edit-video-description',
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.$t("editVideoTitle"))+" ")])]),_c('li',[_c('button',{staticClass:"btn btn-pill subscribe bg-danger",attrs:{"title":""},on:{"click":function($event){return _vm.$router.replace({
                    query: {
                      ..._vm.$route.query,
                      modal: 'remove-video',
                    },
                  })}}},[_vm._v(" "+_vm._s(_vm.$t("removeVideo"))+" ")])])]),_c('div',{staticClass:"clearfix"})])])])]),_c('EditVideoDescription',{attrs:{"video":_vm.video},on:{"update:success":function($event){return _vm.$emit('update:success')}}}),_c('RemoveVideo',{attrs:{"video":_vm.video},on:{"remove:success":(e) => _vm.$emit('remove:success', e)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }