<template>
  <div class="row">
    <div class="col-lg-9">
      <div class="mn-vid-sc single_video">
        <div class="vid-1">
          <div class="vid-info">
            <h3>
              {{ video.description }}
              <div class="float-right">
                <span v-if="video.isActive === 1" class="badge badge-success">{{
                  $t("online")
                }}</span>
                <span v-else class="badge badge-danger">{{
                  $t("offline")
                }}</span>
                <span
                  v-if="video.isPreview === 1"
                  class="badge mx-2 badge-success"
                  >{{ $t("previewVideo") }}</span
                >
                <span
                  v-if="video.interval > 0"
                  class="badge mx-2 badge-success"
                  >{{ $t("waitDayOfVideo", { day: video.interval }) }}</span
                >
              </div>
            </h3>
            <!--info-pr end-->
          </div>
          <!--vid-info end-->
        </div>
        <!--vid-1 end-->
        <div class="abt-mk">
          <div class="info-pr-sec">
            <!--vcp_inf end-->
            <ul class="chan_cantrz">
              <li>
                <button
                  @click="
                    $router.replace({
                      query: {
                        ...$route.query,
                        modal: 'edit-video-description',
                      },
                    })
                  "
                  href="javascript:void(0)"
                  title=""
                  class="btn btn-pill subscribe"
                >
                  {{ $t("editVideoTitle") }}
                </button>
              </li>
              <li>
                <button
                  @click="
                    $router.replace({
                      query: {
                        ...$route.query,
                        modal: 'remove-video',
                      },
                    })
                  "
                  title=""
                  class="btn btn-pill subscribe bg-danger"
                >
                  {{ $t("removeVideo") }}
                </button>
              </li>
            </ul>
            <!--chan_cantrz end-->
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <EditVideoDescription
      @update:success="$emit('update:success')"
      :video="video"
    ></EditVideoDescription>
    <RemoveVideo
      @remove:success="(e) => $emit('remove:success', e)"
      :video="video"
    ></RemoveVideo>
  </div>
</template>

<script>
import EditVideoDescription from "./EditVideoDescription.vue";
import RemoveVideo from "./RemoveVideo.vue";
export default {
  props: {
    video: {
      default: () => {},
    },
    getActiveUser: {
      default: () => {},
    },
  },
  components: { EditVideoDescription, RemoveVideo },
};
</script>

<style></style>
